import React, { useState, useEffect, useCallback } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Stack, TextField } from "@mui/material";
import { FontAwesomeIcon } from "helpers/helpers";

function sleep(duration: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

interface CustomAutoCompleteProps {
  data: any;
  setSearchCriteria: (criteria: any) => void;
  searchCriteria: string;
  mutate: () => void;
  setValue: (value: any) => void;
  value?: any;
  type?: string;
  multiple?: boolean;
  icon?: any;
  clearSelectedUser?: any;
  placeholder?: string;
  ListboxProps?: any;
  isLoading?: boolean;
}

const CustomAutoComplete = ({
  data,
  setSearchCriteria,
  searchCriteria,
  mutate,
  setValue,
  value,
  type,
  multiple,
  icon,
  clearSelectedUser,
  placeholder,
  ListboxProps,
  isLoading,
}: CustomAutoCompleteProps) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const dynamicKey = data
    ? Object.keys(data).find((key) => key !== "pagination")
    : "";
  const dynamicData = dynamicKey ? data[dynamicKey] : [];

  const fetchOptions = useCallback(() => {
    if (!searchCriteria.trim()) return;
    mutate();
  }, [searchCriteria, mutate]);

  const fetchAll = useCallback(() => {
    setLoading(true);
    if (type === "user" && Array.isArray(dynamicData)) {
      const fetchedOptions = dynamicData.map((user: any) => ({
        id: user.member?.id,
        title: `${user.user?.first_name || ""} ${user.user?.last_name || ""}`,
      }));
      setOptions(fetchedOptions);
      setLoading(false);
    }
    if (type === "members" && Array.isArray(dynamicData)) {
      const fetchedOptions = dynamicData.map((user: any) => ({
        id: user?.id,
        title: `${user.user?.first_name || ""} ${user.user?.last_name || ""}`,
      }));
      setOptions(fetchedOptions);
      setLoading(false);
    }
    if (type === "crowds" && Array.isArray(dynamicData)) {
      const fetchedOptions = dynamicData.map((crowd: any) => ({
        id: crowd?.id,
        title: `${crowd.name || ""}`,
      }));
      const allCrowds = [{ id: "All", title: "All Crowds" }];
      setOptions([...allCrowds, ...fetchedOptions]); // Flatten the array before setting it as options
      setLoading(false);
    }
  }, [dynamicData, type]);

  useEffect(() => {
    if (Array.isArray(dynamicData) && dynamicData.length > 0) {
      fetchAll();
    }
  }, [dynamicData, fetchAll]);

  useEffect(() => {
    if (open && options.length === 0) {
      setLoading(true);
      fetchAll();
    }
  }, [open, fetchAll, options.length]);

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    let active = true;

    (async () => {
      await sleep(1e3);

      if (active) {
        fetchOptions();
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, fetchOptions]);

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      key={type}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      multiple={multiple}
      freeSolo
      filterSelectedOptions
      value={value}
      ListboxProps={ListboxProps}
      inputValue={inputValue}
      disableCloseOnSelect={multiple}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === "reset") {
          setSearchCriteria("");
          // setValue(null);
          setInputValue("");
          return;
        } else {
          setInputValue(newInputValue);
          setSearchCriteria(newInputValue);
        }
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option?.title
      }
      getOptionKey={(option) => option?.id}
      options={options}
      loading={loading || isLoading}
      onChange={(event, newValue, reason) => {
        if (type === "crowds") {
          clearSelectedUser();
        }
        setValue(newValue);
        if (!multiple) {
          setInputValue(newValue?.title || "");
        }
      }}
      renderInput={(params) => (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="center"
        >
          {icon && <FontAwesomeIcon icon={icon} />}
          <TextField
            placeholder={placeholder ? placeholder : "Select..."}
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        </Stack>
      )}
    />
  );
};

export default CustomAutoComplete;
