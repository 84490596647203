import React from "react";
import AnimatedIconButton from "../AnimatedIconButton";
import { FontAwesomeIcon } from "@/helpers/helpers";
import fileDownloader from 'lib/fileDownloader';
import { Tooltip } from "@mui/material";

interface ServerDownloadProps {
  tooltipText: string;
  apiUrl: string;
  disabled?: boolean;
}

export default function ServerDownload({
  tooltipText,
  apiUrl,
  disabled,
}: ServerDownloadProps) {

  const handleDownload = async () => {
    try {
      await fileDownloader(apiUrl);
    } catch (error) {
      console.error('Error downloading report:', error);
    }
  };

  return (
    <Tooltip title={tooltipText}>
      <span>
        <AnimatedIconButton
          disabled={disabled}
          ariaLabel={tooltipText}
          icon={<FontAwesomeIcon icon="download" />}
          onClick={handleDownload}
        />
      </span>
    </Tooltip>
  );
}
