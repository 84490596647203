import React from "react";
import AnimatedIconButton from "../AnimatedIconButton";
import { FontAwesomeIcon } from "@/helpers/helpers";
import { Tooltip } from "@mui/material";

interface ClientDownloadProps {
  fileName: string;
  tooltipText: string;
  data: string | Blob | File | any[];
  onDownloadStart?: () => void;
  onDownloadComplete?: () => void;
}

export default function ClientDownload({
  fileName,
  tooltipText,
  data,
  onDownloadStart,
  onDownloadComplete,
}: ClientDownloadProps) {
  const handleDownload = () => {
    onDownloadStart?.();

    // Convert array of objects to CSV
    const convertToCSV = (data: any[]) => {
      const headers = Object.keys(data[0]);
      const rows = data.map(obj => 
        headers.map(header => JSON.stringify(obj[header])).join(',')
      );
      return [headers.join(','), ...rows].join('\n');
    };

    const csvData = Array.isArray(data) ? convertToCSV(data) : data;
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    onDownloadComplete?.();
  };

  return (
    <Tooltip title={tooltipText}>
      <span>
        <AnimatedIconButton
          ariaLabel={tooltipText}
          icon={<FontAwesomeIcon icon="download" />}
          onClick={handleDownload}
        />
      </span>
    </Tooltip>
  );
}
