import {
  Box,
  Paper,
  Stack,
  MenuItem,
  InputBase,
  styled,
  SelectChangeEvent,
  IconButton,
} from "@mui/material";
import styles from "./index.module.css";
import DashboardChart from "components/chart/DashboardChart";
import { useEffect, useState } from "react";
import SelectorWithIcon from "components/CustomUI/SelectorWithIcon";
import useApi from "lib/useApi";
import RangePicker from "components/CustomUI/RangePicker";
import dayjs from "dayjs";
import { BlackTooltip, FontAwesomeIcon } from "helpers/helpers";
import { useLocalStorage } from "contexts/LocalStorageContext";
import fileDownloader from "@/lib/fileDownloader";
import ServerDownload from "@/components/common/ServerDownload";
interface Props {}

export default function ParticipantEarningsGraph({}: Props) {
  const { orgId, isMobile } = useLocalStorage();
  const [date, setDate] = useState([
    dayjs.utc().subtract(3, "month").startOf("month"),
    dayjs.utc().endOf("month"),
  ]);
  const [crowdTypeSelected, setCrowdTypeSelected] = useState("all");
  const [type, setType] = useState("all");

  const [downloading, setDownloading] = useState(false);

  const [myChartData, setMyChartData] = useState({} as any);
  const { data: myChartDataRoot } = useApi(
    `/api/orgs/${orgId}/earnings/my_chart_data?start_date=${date[0]}&end_date=${
      date[1]
    }${crowdTypeSelected !== "all" ? `&crowd_id=${crowdTypeSelected}` : ""}${
      type !== "all" ? `&earning_type=${type} ` : ""
    }`,
    true,
    { immutable: true }
  );

  const [myCrowds, setMyCrowds] = useState([]);
  const { data: crowds } = useApi(`/api/orgs/${orgId}/crowds/my_crowds`, true, {
    immutable: true,
  });

  const buildEarningsQueryUrl = (baseUrl: string) => {
    const params = new URLSearchParams();
    
    params.append('start_date', date[0].format("YYYY-MM-DD"));
    params.append('end_date', date[1].format("YYYY-MM-DD"));
    
    if (crowdTypeSelected !== "all") {
      params.append('crowd_id', crowdTypeSelected);
    }
    
    if (type !== "all") {
      params.append('earning_type', type);
    }
    
    return `${baseUrl}?${params.toString()}`;
  };

  useEffect(() => {
    if (crowds) {
      const myCrowds = crowds.crowds?.map((crowd) => ({
        id: crowd.id,
        name: crowd.name,
      }));
      setMyCrowds(myCrowds);
    }
  }, [crowds]);

  useEffect(() => {
    if (myChartDataRoot) {
      setMyChartData(myChartDataRoot);
    }
  }, [myChartDataRoot]);

  const handleChangeTypesCrowds = (event: SelectChangeEvent) => {
    setCrowdTypeSelected(event.target.value);
  };

  return (
    <div className="bg-white rounded-xl p-4 h-full">
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            fontSize: "1.2rem",
            whiteSpace: "nowrap",
            fontWeight: "bold",
          }}
        >
          <Stack>Earnings</Stack>
          <ServerDownload
            tooltipText="Download CSV"
            apiUrl={buildEarningsQueryUrl(`/api/orgs/${orgId}/earnings/my_earnings.csv`)}
          />
        </Stack>
      </Stack>

      <Stack
        width="100%"
        justifyContent="flex-end"
        mt={2}
        direction="row"
        spacing={3}
        flexWrap={"wrap"}
      >
        <SelectorWithIcon
          value={crowdTypeSelected}
          onChange={handleChangeTypesCrowds}
          // icon="user-group-simple"
        >
          <MenuItem value={"all"}>All</MenuItem>
          {myCrowds?.map((type: any) => (
            <MenuItem key={type?.id} value={type?.id}>
              {type?.name}
            </MenuItem>
          ))}
        </SelectorWithIcon>

        <SelectorWithIcon
          value={type}
          onChange={(e) => setType(e.target.value)}
          // icon="award"
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"gift"}>Gift</MenuItem>
          <MenuItem value={"reward"}>Reward</MenuItem>
          <MenuItem value={"adjustment"}>Adjustment</MenuItem>
          <MenuItem value={"signup_bonus"}>Signup Bonus</MenuItem>
        </SelectorWithIcon>

        <RangePicker date={date} setDate={setDate} />
      </Stack>
      <Box
        sx={{
          position: "relative",
          height: isMobile ? "450px" : "310px",
          pt: 2,
          pb: isMobile ? 2 : 0,
        }}
      >
        <DashboardChart data={myChartData} />
      </Box>
    </div>
  );
}
