import SearchBox from "components/CustomUI/SearchBox";
import { CustomPagination } from "helpers/helpers";
import useApi from "lib/useApi";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { convertToHyperlinks } from "helpers/helpers";
import React from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  flexRender,
  createColumnHelper,
  ColumnResizeMode,
} from "@tanstack/react-table";
// font awesome icons
import { FontAwesomeIcon } from "helpers/helpers";
import fileDownloader from "@/lib/fileDownloader";

interface Table {
  date?: any;
  admin?: any;
  orgId?: any;
  crowd?: any;
  type?: any;
  selectedUser?: any;
  setRowCount?: any;
  rowCount?: any;
  excludeDeactivatedMembers?: boolean;
}

export default function OrgEarningsTable({
  date,
  admin,
  orgId,
  crowd,
  type,
  selectedUser,
  setRowCount,
  rowCount,
  excludeDeactivatedMembers,
}: Table) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [searchCriteria, setSearchCriteria] = useState("");

  const [sortModel, setSortModel] = useState<SortingState>([
    { id: "date", desc: true },
  ]);
  const sortField = sortModel[0]?.id || "";
  const sortOrder = sortModel[0]?.desc ? "desc" : "asc";

  const { data: earnings, isLoading } = useApi(
    `/api/orgs/${orgId}/earnings?start_date=${date[0]}&end_date=${
      date[1]
    }&filter=${searchCriteria}${
      type === "all" ? "" : `&earning_type=${type}`
    }&page=${paginationModel.page + 1}&per_page=${paginationModel.pageSize}${
      selectedUser === null || selectedUser === ""
        ? ""
        : `&member_id=${selectedUser.id}`
    }${
      crowd === "All" || crowd === undefined ? "" : `&crowd_id=${crowd}`
    }&sort[field]=${sortField}&sort[order]=${sortOrder}&exclude_deactivated_members=${excludeDeactivatedMembers}`,
    admin
  );

  const earningsData = earnings?.transactions || [];

  useEffect(() => {
    if (earnings) {
      setRowCount(earnings?.pagination?.total_items);
    }
  }, [earnings]);

  const rows = earningsData?.map((row: any) => {
    return {
      ...row,
      id: row.id,
      first_name: row.member.first_name,
      last_name: row.member.last_name,
      email: row.member.email,
      status: row.member.status,
      role: row.member.role,
      amount: row.amount,
      transaction_type: row.transaction_type,
      crowd_name: row.crowd.name,
      description: row.description,
      date: dayjs.utc(row.occurred_at).format("MM/DD/YY"),
    };
  });

  const columnHelper = createColumnHelper<any>();

  const columns = [
    columnHelper.accessor("date", {
      header: "Date",
      size: 80,
    }),
    columnHelper.accessor("first_name", {
      header: "First Name",
      size: 130,
    }),
    columnHelper.accessor("last_name", {
      header: "Last Name",
      size: 130,
    }),
    columnHelper.accessor("crowd_name", {
      header: "Crowd",
      size: 130,
    }),
    columnHelper.accessor("transaction_type", {
      header: "Type",
      size: 70,
    }),
    columnHelper.accessor("description", {
      header: "Description",
      size: 250,
      cell: ({ row }) => {
        const formattedDescription = convertToHyperlinks(
          row.original.description
        );
        return (
          <span className="whitespace-normal leading-normal">
            {row.original.link ? (
              <a
                href={row.original.link}
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline"
              >
                {row.original.description}
              </a>
            ) : React.isValidElement(formattedDescription) ? (
              <span
                dangerouslySetInnerHTML={{ __html: row.original.description }}
              />
            ) : (
              row.original.description
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("amount", {
      header: "Amount",
      size: 80,
    }),
  ];

  const [columnResizeMode] = useState<ColumnResizeMode>("onChange");

  const table = useReactTable({
    data: rows || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sortModel,
    },
    onSortingChange: setSortModel,
    manualPagination: true,
    manualSorting: true,
    pageCount: Math.ceil(rowCount / paginationModel.pageSize),
    enableColumnResizing: true,
    columnResizeMode,
  });

  return (
    <div className="bg-white rounded-xl pt-4 grid grid-rows-[auto_1fr_auto] h-full">
      <SearchBox
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
      />

      <div className="overflow-x-auto rounded-xl border border-[#e5e7eb] h-full relative">
        {isLoading && (
          <div className="absolute top-0 left-0 w-full h-[3px] bg-gradient-to-r from-purple-500 to-blue-500 animate-table-loader z-10" />
        )}
        <table
          className="min-w-full table-fixed"
          style={{ width: table.getCenterTotalSize() }}
        >
          <thead className="sticky top-0 bg-[#f9fafb] rounded-t-xl h-[40px]">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="px-2 py-1 text-left text-xs font-bold text-[#6B7280] uppercase relative"
                    style={{
                      width: header.getSize(),
                      position: "relative",
                    }}
                  >
                    {header.column.getCanSort() ? (
                      <div
                        className="cursor-pointer select-none flex items-center gap-1 hover:text-gray-700"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getIsSorted() === "asc" ? (
                          <FontAwesomeIcon
                            icon="arrow-up"
                            color="#a60ed8"
                            className="w-4 h-4"
                          />
                        ) : header.column.getIsSorted() === "desc" ? (
                          <FontAwesomeIcon
                            icon="arrow-down"
                            color="#a60ed8"
                            className="w-4 h-4"
                          />
                        ) : null}
                      </div>
                    ) : (
                      flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )
                    )}
                    <div
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                      className={`absolute right-0 top-0 h-full w-[2px] cursor-col-resize select-none touch-none ${
                        header.column.getIsResizing()
                          ? "bg-purple-500"
                          : "bg-gray-100"
                      }`}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {isLoading ? (
              <tr>
                <td colSpan={columns.length} className="text-center py-1"></td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="px-4 py-1 text-sm text-gray-500 bg-white whitespace-nowrap overflow-hidden"
                      style={{
                        width: cell.column.getSize(),
                        backgroundColor: "white",
                        // display: "block",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex items-center justify-between">
        <select
          value={paginationModel.pageSize}
          onChange={(e) => {
            setPaginationModel({
              ...paginationModel,
              pageSize: Number(e.target.value),
            });
          }}
          className="select select-bordered select-primary select-sm rounded-xl"
        >
          {[5, 10, 25, 50, 100, rowCount].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize === rowCount
                ? `Show Max (${rowCount})`
                : `Show ${pageSize}`}
            </option>
          ))}
        </select>
        <div className="text-xs text-gray-500">
          {/* Rows per page: {paginationModel.pageSize} */}
          <span className="ml-4">
            {paginationModel.page * paginationModel.pageSize + 1}–
            {Math.min(
              (paginationModel.page + 1) * paginationModel.pageSize,
              rowCount
            )}{" "}
            of {rowCount}
          </span>
        </div>
        <div className="join">
          <button
            className={`join-item btn btn-sm ${
              paginationModel.page === 0 ? "btn-disabled" : ""
            }`}
            onClick={() =>
              setPaginationModel({
                ...paginationModel,
                page: Math.max(0, paginationModel.page - 1),
              })
            }
          >
            «
          </button>

          <button
            className={`join-item btn btn-sm ${
              paginationModel.page === 0 ? "btn-primary" : ""
            }`}
            onClick={() => setPaginationModel({ ...paginationModel, page: 0 })}
          >
            1
          </button>

          {paginationModel.page > 2 && (
            <button className="join-item m-2">...</button>
          )}

          {paginationModel.page > 1 && (
            <button
              className="join-item btn btn-sm"
              onClick={() =>
                setPaginationModel({
                  ...paginationModel,
                  page: paginationModel.page - 1,
                })
              }
            >
              {paginationModel.page}
            </button>
          )}

          {paginationModel.page > 0 &&
            paginationModel.page <
              Math.ceil(rowCount / paginationModel.pageSize) - 1 && (
              <button className="join-item btn btn-sm btn-primary">
                {paginationModel.page + 1}
              </button>
            )}

          {paginationModel.page <
            Math.ceil(rowCount / paginationModel.pageSize) - 2 && (
            <button
              className="join-item btn btn-sm"
              onClick={() =>
                setPaginationModel({
                  ...paginationModel,
                  page: paginationModel.page + 1,
                })
              }
            >
              {paginationModel.page + 2}
            </button>
          )}

          {paginationModel.page <
            Math.ceil(rowCount / paginationModel.pageSize) - 3 && (
            <button className="join-item m-2">...</button>
          )}

          {Math.ceil(rowCount / paginationModel.pageSize) > 1 && (
            <button
              className={`join-item btn btn-sm ${
                paginationModel.page ===
                Math.ceil(rowCount / paginationModel.pageSize) - 1
                  ? "btn-disabled"
                  : ""
              }`}
              onClick={() =>
                setPaginationModel({
                  ...paginationModel,
                  page: Math.ceil(rowCount / paginationModel.pageSize) - 1,
                })
              }
            >
              {Math.ceil(rowCount / paginationModel.pageSize)}
            </button>
          )}

          <button
            className={`join-item btn btn-sm ${
              paginationModel.page ===
              Math.ceil(rowCount / paginationModel.pageSize) - 1
                ? "btn-disabled"
                : ""
            }`}
            onClick={() =>
              setPaginationModel({
                ...paginationModel,
                page: Math.min(
                  Math.ceil(rowCount / paginationModel.pageSize) - 1,
                  paginationModel.page + 1
                ),
              })
            }
          >
            »
          </button>
        </div>
      </div>
    </div>
  );
}
