const getFilenameFromHeader = (contentDisposition: string | null, url: string): string => {
  // Extract filename from URL as content-disposition is not accessible
  const urlParts = url.split('/');
  const rawFilename = urlParts[urlParts.length - 1];
  
  // Find the position of '.csv' and take everything before it
  const csvIndex = rawFilename.toLowerCase().indexOf('.csv');
  const baseFilename = csvIndex !== -1 
    ? rawFilename.substring(0, csvIndex + 4) // Include the '.csv'
    : rawFilename.split('?')[0]; // Fallback to original behavior
  
  // Add date to the filename
  const now = new Date();
  const date = now.toISOString().split('T')[0];
  const [name, extension] = baseFilename.split('.');
  
  // Truncate name to 30 characters if longer (increased from 15)
  const truncatedName = name.length > 30 ? name.slice(0, 30) : name;
  
  return `${truncatedName}-${date}.${extension}`;
};

const fileDownloader = async (url: string) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token is not available");
    }

    const baseUrl = process.env.NEXT_PUBLIC_CUSTOMER_API_HOST;
    const encodedUrl = new URL(url, baseUrl).toString();
    
    const response = await fetch(encodedUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'text/csv',
      },
    });

    if (!response.ok) {
      throw new Error(`Download failed: ${response.statusText}`);
    }

    const contentDisposition = response.headers.get('content-disposition');
    const filename = getFilenameFromHeader(contentDisposition, url);

    const text = await response.text();
    const BOM = '\uFEFF';
    const csvContent = BOM + text;
    
    const blob = new Blob([csvContent], { 
      type: 'application/csv;charset=utf-8;' 
    });
    
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = 'none';
    a.href = downloadUrl;
    a.download = filename;
    
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(() => {
      window.URL.revokeObjectURL(downloadUrl);
    }, 100);
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error;
  }
};

export default fileDownloader;
