import { useEffect, useState } from "react";
import { Box, Stack, MenuItem, IconButton, Divider, Autocomplete, TextField } from "@mui/material";
import dayjs from "dayjs";
import { BlackTooltip, FontAwesomeIcon } from "helpers/helpers";
import DoughnutChart from "components/chart/AdminPieChart";
import useApi from "lib/useApi";
import SelectorWithIcon from "components/CustomUI/SelectorWithIcon";
import RangePicker from "components/CustomUI/RangePicker";
import CustomAutoComplete from "components/CustomUI/CustomAutoComplete";
import { useLocalStorage } from "contexts/LocalStorageContext";
import fileDownloader from "@/lib/fileDownloader";
import ServerDownload from "@/components/common/ServerDownload";

export default function OrgRedemption() {
  const { admin, allMembersId, isMobile } = useLocalStorage();
  const [selectedCrowd, setSelectedCrowd] = useState<{
    id: string;
    name: string;
  } | null>({
    id: "All",
    name: "All Crowds",
  });
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [searchCriteria, setSearchCriteria] = useState("");
  const [searchCriteriaUsers, setSearchCriteriaUsers] = useState("");
  const [type, setType] = useState("all");
  const [crowdsListHook, setCrowdsListHook] = useState([
    { id: "All Members", name: "All Members" },
  ]);
  const [date, setDate] = useState([
    dayjs.utc().startOf("month"),
    dayjs.utc().endOf("month"),
  ]);
  const [valueType, setValueType] = useState("points");
  const [options, setOptions] = useState<Array<{ id: string; name: string }>>(
    []
  );
  const [inputValue, setInputValue] = useState("");

  const [crowdsPage, setCrowdsPage] = useState(12);
  const [usersPage, setUsersPage] = useState(12);
  const [totalCrowds, setTotalCrowds] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const localStorage = () => {
    if (typeof window !== "undefined") {
      return window.localStorage;
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const valueType = localStorage()?.valueType;
      setValueType(valueType);
    }
  }, [localStorage()?.admin]);
  // end

  // const findIdOfCrowd = (crowdName: string) => {
  //   const crowd = crowdsListHook?.find(
  //     (crowd: any) => crowd.name === crowdName
  //   );
  //   return crowd?.id;
  // };

  // const findNameOfCrowd = (crowdName: string) => {
  //   const crowd = crowdsListHook?.find(
  //     (crowd: any) => crowd.name === crowdName
  //   );
  //   return crowd?.name || "All Crowds";
  // };

  const orgId = localStorage()?.orgSelected;

  const { data: allCrowds, mutate: mutateAllCrowds } = useApi(
    `/api/orgs/${orgId}/crowds?filter=${searchCriteria}&page=1&per_page=${crowdsPage}`,
    admin
  );

  const allCrowdsList = allCrowds?.crowds;

  const {
    data: crowdUsers,
    mutate: mutateUsers,
    isLoading: usersLoading,
  } = useApi(
    `/api/orgs/${orgId}/crowds/${
      selectedCrowd?.id === "All" || selectedCrowd === null
        ? allMembersId
        : selectedCrowd?.id
    }/everyone?filter=${searchCriteriaUsers}&page=1&per_page=${usersPage}`,
    admin
  );
  // Handle scroll event for crowds
  const handleCrowdsScroll = (event) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight >=
        listboxNode.scrollHeight - 10 &&
      crowdsPage < totalCrowds
    ) {
      setCrowdsPage((prevPage) => prevPage + 10);
    }
  };

  // Handle scroll event for users
  const handleUsersScroll = (event) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight >=
        listboxNode.scrollHeight - 10 &&
      usersPage < totalUsers
    ) {
      setUsersPage((prevPage) => prevPage + 10);
    }
  };

  useEffect(() => {
    setTotalUsers(crowdUsers?.pagination?.total_items);
  }, [crowdUsers]);

  useEffect(() => {
    if (allCrowds === undefined) return;
    const fetchedOptions = allCrowds?.crowds.map((crowd: any) => ({
      id: crowd?.id,
      name: `${crowd.name || ""}`,
    }));
    const all_crowds = [{ id: "All", name: "All Crowds" }];
    setTotalCrowds(allCrowds?.pagination?.total_items);
    setOptions([...all_crowds, ...fetchedOptions]);
  }, [allCrowds]);

  const buildRedemptionQueryUrl = (baseUrl: string) => {
    const params = new URLSearchParams();
    
    // Add crowd_id parameter
    params.append('crowd_id', 
      selectedCrowd?.id === "All" || selectedCrowd === null
        ? allMembersId
        : selectedCrowd?.id
    );
    
    // Add date parameters
    params.append('start_date', date[0]?.toISOString());
    params.append('end_date', date[1]?.toISOString());
    
    // Add reward_type if not "all"
    if (type !== "all") {
      params.append('reward_type', type);
    }
    
    // Add member_id if user is selected
    if (selectedUser !== null && selectedUser !== "") {
      params.append('member_id', selectedUser.id);
    }
    
    return `${baseUrl}?${params.toString()}`;
  };

  const downloadRedemptionReport = async () => {
    try {
      await fileDownloader(
        buildRedemptionQueryUrl(`/api/orgs/${orgId}/rewards/redemption_details.csv`)
      );
    } catch (error) {
      console.error("Error downloading report:", error);
    }
  };

  const { data: redemptionSummary } = useApi(
    `/api/orgs/${orgId}/rewards/redemption_summary?crowd_id=${
      selectedCrowd?.id === "All" || selectedCrowd === null
        ? allMembersId
        : selectedCrowd?.id
    }&start_date=${date[0]}&end_date=${date[1]}${
      type !== "all" ? `&reward_type=${type}` : ""
    }${
      selectedUser === null || selectedUser === ""
        ? ""
        : `&member_id=${selectedUser.id}`
    }`,
    admin
  );

  useEffect(() => {
    if (admin && allCrowdsList?.length > 0) {
      const crowdsList = allCrowdsList?.map((crowd: any) => ({
        id: crowd?.id,
        name: crowd?.name,
      }));
      setCrowdsListHook(crowdsList);
    }
  }, [allCrowdsList]);

  const clearSelectedUser = () => {
    setSearchCriteria("");
    setSelectedUser("");
  };

  return (
    <div className="bg-white rounded-xl p-4 h-full">
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            fontSize: "1.2rem",
            whiteSpace: "nowrap",
            fontWeight: "bold",
          }}
        >
          <Stack>Org Redemption History</Stack>
          <ServerDownload
            tooltipText="Download CSV"
            disabled={redemptionSummary?.length === 0}
            apiUrl={buildRedemptionQueryUrl(`/api/orgs/${orgId}/rewards/redemption_details.csv`)}
          />
        </Stack>
        <RangePicker date={date} setDate={setDate} />
      </Stack>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-1 mt-2">
        <Stack direction="row" alignItems="center">
          <Autocomplete
            sx={{ width: "100%" }}
            options={options || []}
            getOptionLabel={(option) => option.name}
            value={selectedCrowd}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              clearSelectedUser();
              setInputValue(newInputValue);
            }}
            onChange={(event, newValue) => {
              setSelectedCrowd(newValue);
              if (newValue) {
                setInputValue(newValue.name);
              }
            }}
            filterSelectedOptions
            ListboxProps={{
              onScroll: handleCrowdsScroll,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                onChange={(e) => {
                  setSearchCriteria(e.target.value);
                  setCrowdsPage(1); // Reset page to 1 for new search
                  setOptions([]);
                }}
                placeholder="Select Crowd..."
              />
            )}
          />
        </Stack>

        <CustomAutoComplete
          type="user"
          // icon="user"
          placeholder="Select User..."
          setSearchCriteria={setSearchCriteriaUsers}
          mutate={mutateUsers}
          searchCriteria={searchCriteriaUsers}
          data={crowdUsers}
          setValue={setSelectedUser}
          value={selectedUser}
          isLoading={usersLoading}
          ListboxProps={{
            onScroll: handleUsersScroll,
          }}
        />

        <SelectorWithIcon
          value={type}
          onChange={(e) => setType(e.target.value)}
          // icon="award"
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"gift_card"}>Gift Cards</MenuItem>
          <MenuItem value={"custom"}>Custom Rewards</MenuItem>
        </SelectorWithIcon>
      </div>

      <Divider sx={{ mt: 1 }} />

      <Box
        sx={{
          position: "relative",
          height: "407px",
          pt: 2,
        }}
      >
        {redemptionSummary ? (
          <DoughnutChart
            redemptionSummary={redemptionSummary}
            valueType={valueType}
          />
        ) : null}
      </Box>
    </div>
  );
}
